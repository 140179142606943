














































































import { Vue, Component, Watch } from "vue-property-decorator";
@Component
export default class Layout extends Vue {}
